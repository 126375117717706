import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "API DB Coupling",
  "author": "tzookb",
  "type": "post",
  "date": "2022-08-24T13:50:08.000Z",
  "url": "/api-db-coupling/",
  "featuredImage": "./manage.jpg",
  "desc": "how to manage yourself, and mange your managers",
  "categories": ["API", "DB modeling"],
  "unpublished": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`After going through a short friendly twitter discussion on "db model - api" implementation.
I wanted to make sure, I can share a nice elaborated doc on why I think db models should be decoupled from api strcutures.`}</p>
    <p>{`follow up this tweet
`}<a parentName="p" {...{
        "href": "https://twitter.com/EndlessMason/status/1562456397748793347"
      }}>{`https://twitter.com/EndlessMason/status/1562456397748793347`}</a></p>
    <ul>
      <li parentName="ul">{`column name changes`}</li>
      <li parentName="ul">{`calculated attributes`}</li>
      <li parentName="ul">{`persistence layer changes`}</li>
      <li parentName="ul">{`hidden columns`}</li>
      <li parentName="ul">{`different data types`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      